import {
    BrandHits,
    BrandSearchAndFilter,
    PGPlusBrandSearch,
} from '@/src/modules/algolia/PGPlusBrandSearch'
import { styled, theme } from '@/src/stitches.config'
import Image from 'next/image'
import { getBuilderBannersOrPromos } from '@/src/common/queries/getBuilderBannersOrPromos'
import { BrandSearchContextProvider } from '@/src/modules/algolia/BrandSearchContext'
import { getServerState } from 'react-instantsearch-core'
import { renderToString } from 'react-dom/server'
import { InferGetStaticPropsType } from 'next'
import { StillSearchingForPerfectGift } from '@/src/common/components/marketing/brands/StillSearchingForPerfectGift'
import addGradientToSubstring from '@/src/common/utilities/addGradientToSubstring'

const ComponentWrapper = styled('div', {
    minHeight: '50vh',
})

const BackgroundColor = styled('div', {
    background: '$lightest',
    variants: {
        variant: {
            gray: {
                background: '$lightest',
            },
            white: {
                background: '$white',
            },
        },
    },
    defaultVariants: {
        variant: 'gray',
    },
})

const StyledMaxWidthContainer = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    margin: 'auto',
    display: 'flex',
    ai: 'center',
    jc: 'center',
    position: 'relative',
    gap: '1%',
})

const StyledHalfWidthContainer = styled('div', {
    flex: '1 1 auto',
    variants: {
        variant: {
            image: {
                display: 'none',
                '@md': {
                    display: 'block',
                    flex: '.5 1 auto',
                    maxWidth: '50%',
                },
            },
            search: {
                py: 20,
                width: '100%',
                '@md': {
                    width: 'auto',
                    flex: '1 1 auto',
                    textAlign: 'right',
                    maxWidth: 550,
                    marginLeft: 'auto',
                },
            },
        },
    },
})

const StyledImage = styled(Image, {
    display: 'block',
    height: 285,
    maxHeight: '100%',
    width: 'auto',
    objectFit: 'cover',
    objectPosition: 'left top',
    '@lg': {
        height: 330,
    },
})

const TextAndSearchWrapper = styled('div', {
    fontSize: '1.15rem',
    '.brand-search': {
        marginLeft: 0,
    },
})

const StyledH1 = styled('h1', {
    fontSize: '2rem',
    my: 0,
    span: {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    '@sm': {
        fontSize: '2.65rem',
    },
    '@lg': {
        fontSize: '3rem',
    },
})

const BrandsWrapper = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    margin: 'auto',
    mb: 40,
})

type TBrandPageProps = {
    algoliaServerState: InferGetStaticPropsType<any>
    headerImage?: string
    headerText?: string
    gradientString?: string
    headerDescription?: string
    validBrands?: string[]
    isPartnerFlow?: boolean
}

const BrandsPage = ({
    algoliaServerState,
    headerImage,
    headerText = 'Explore eGifts to hundreds of top brands!',
    gradientString = 'eGifts',
    headerDescription = ' Send premium personalized cards from popular merchants. There’s something for everyone.',
    validBrands,
    isPartnerFlow,
}: TBrandPageProps) => {
    const headerTextWithGradient = addGradientToSubstring(
        headerText,
        gradientString
    )

    return (
        <>
            <BrandSearchContextProvider
                flow={isPartnerFlow ? 'partner' : 'search'}
            >
                <ComponentWrapper>
                    <PGPlusBrandSearch
                        serverState={algoliaServerState}
                        validBrands={validBrands}
                    >
                        <BackgroundColor
                            variant={headerImage ? 'white' : 'gray'}
                        >
                            <StyledMaxWidthContainer>
                                <StyledHalfWidthContainer variant={'image'}>
                                    <StyledImage
                                        src={
                                            headerImage ??
                                            '/images/brand_search_image_2.png'
                                        }
                                        alt={'cell phones image'}
                                        height={380}
                                        width={601}
                                        data-testid={'brand-search-image'}
                                        priority
                                        quality={100}
                                    />
                                </StyledHalfWidthContainer>
                                <StyledHalfWidthContainer variant={'search'}>
                                    <TextAndSearchWrapper>
                                        <StyledH1
                                            data-testid={'brand-search-h1'}
                                        >
                                            {headerTextWithGradient}
                                        </StyledH1>
                                        <p
                                            className={'right-align'}
                                            data-testid={'brand-search-body'}
                                        >
                                            {headerDescription}
                                        </p>
                                        <BrandSearchAndFilter />
                                    </TextAndSearchWrapper>
                                </StyledHalfWidthContainer>
                            </StyledMaxWidthContainer>
                        </BackgroundColor>
                        <BrandsWrapper>
                            <BrandHits />
                        </BrandsWrapper>
                        <>
                            {!isPartnerFlow && <StillSearchingForPerfectGift />}
                        </>
                    </PGPlusBrandSearch>
                </ComponentWrapper>
            </BrandSearchContextProvider>
        </>
    )
}

export default BrandsPage

export async function getStaticProps() {
    const { consumerData: consumerBanner } = await getBuilderBannersOrPromos(
        true
    )

    const algoliaServerState = await getServerState(
        <PGPlusBrandSearch>
            <BrandHits />
        </PGPlusBrandSearch>,
        {
            renderToString,
        }
    )

    return {
        props: {
            consumerBanner,
            algoliaServerState,
            content: {
                data: {
                    title: 'Search Top Gift Card Brands',
                    description:
                        'Explore eGifts to hundreds of top brands! Send premium personalized cards from popular merchants. There’s something for everyone.',
                },
            },
        },
        revalidate: 30,
    }
}
